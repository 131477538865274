@charset "UTF-8";
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/** TODO: Add more accent colors */
/** TODO: Add red palette */
/** TODO: Add yellow palette */
/** TODO: Add more accent colors */
/** TODO: Add red palette */
/** TODO: Add yellow palette */
/** TODO: Add more accent colors */
/** TODO: Add red palette */
/** TODO: Add yellow palette */
@import "~@one/icons/dist/one-icons.css";
@import "~roboto-fontface/css/roboto/roboto-fontface.css";
.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/19px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 14px/16px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(16px * 0.83)/19px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(16px * 0.67)/19px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 400 14px/16px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 16px/19px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/14px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 96px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 300 60px/71px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 48px/57px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 16px/19px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 16px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 400;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 16px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 16px/19px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  -ms-transform: translateY(-1.28119em) scale(0.75);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  -ms-transform: translateY(-1.28118em) scale(0.75);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  -ms-transform: translateY(-1.28117em) scale(0.75);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 400;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 400 14px/16px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

@font-face {
  font-family: "Roboto";
  src: "css/roboto/sass/roboto-fontface-light.scss" format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: "css/roboto/sass/roboto-fontface-regular.scss" format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: "css/roboto/sass/roboto-fontface-medium.scss" format("truetype");
  font-weight: 500;
  font-style: normal;
}
mat-calendar.one-datepicker {
  min-width: 481px !important;
  height: 344px !important;
}
mat-calendar.one-datepicker .one-quick-selection {
  position: absolute;
  padding: 14px 16px 16px;
}
mat-calendar.one-datepicker .one-quick-selection .one-quick-selection-title {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 16px;
}
mat-calendar.one-datepicker .mat-calendar-header {
  width: 276px;
  float: right;
  padding: 4px 8px 8px;
}
mat-calendar.one-datepicker .mat-calendar-content {
  margin-left: 185px;
}
mat-calendar .mat-button .mat-button-wrapper {
  font-size: 14px;
}
mat-calendar .mat-calendar-body-cell-content {
  font-size: 14px;
}
mat-calendar .mat-calendar-controls {
  margin-top: 0;
  margin-bottom: 0;
}
mat-calendar .mat-calendar-arrow {
  margin-left: 16px;
}
mat-calendar .mat-calendar-body-label {
  visibility: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
mat-calendar .mat-calendar-body-cell-content.mat-calendar-body-selected {
  font-weight: bold;
}
mat-calendar .mat-datepicker-content .mat-calendar {
  height: 344px;
}
mat-calendar table.mat-calendar-table {
  margin-top: 9px;
}
mat-calendar .mat-calendar-table-header th {
  font-size: 14px;
}
mat-calendar .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.one-editable-dropdown input {
  cursor: pointer;
}
.one-editable-dropdown .mat-form-field-flex {
  cursor: pointer;
}
.one-editable-dropdown.mat-form-field-disabled input {
  cursor: default;
}
.one-editable-dropdown.mat-form-field-disabled .mat-form-field-flex {
  cursor: default;
}
.one-editable-dropdown .mat-form-field-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.one-editable-dropdown .mat-form-field-suffix [one-icon-button-flat] {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 6px;
}
.one-editable-dropdown.mat-form-field-appearance-fill .mat-form-field-suffix {
  margin-top: 12px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-flex {
  height: 40px;
  font-size: 14px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-infix {
  padding: 2px 0 13px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-suffix {
  margin-top: 6px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  height: 48px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-flex {
  height: 36px;
  min-height: 36px;
  font-size: 14px;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-infix {
  padding: 4px 0 13px;
  border-top: none;
}
.one-editable-dropdown.mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.one-editable-dropdown-slim .mat-form-field-wrapper .mat-form-field-suffix {
  margin-top: 6px;
}
.one-editable-dropdown .mat-form-field-prefix .mat-icon,
.one-editable-dropdown .mat-form-field-suffix .mat-icon {
  line-height: 1;
}

mat-form-field.one-search-field {
  flex: 1;
  min-width: 250px;
}
mat-form-field.one-search-field .mat-form-field-flex {
  padding: 0 12px;
  flex-direction: row;
  align-items: stretch;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-infix {
  border-top: 0;
  font-size: 14px;
  line-height: normal;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-prefix .mat-icon,
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-suffix .mat-icon {
  line-height: 1;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-suffix {
  display: flex;
  justify-content: center;
  align-items: center;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-suffix [one-icon-button-flat] {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}
mat-form-field.one-search-field.one-search-field-slim .mat-form-field-flex {
  height: 36px;
}
mat-form-field.one-search-field.one-search-field-slim .mat-form-field-flex .mat-form-field-infix {
  padding: 10px 0;
}
mat-form-field.one-search-field.one-search-field-regular .mat-form-field-flex {
  height: 48px;
}
mat-form-field.one-search-field.one-search-field-regular .mat-form-field-flex .mat-form-field-infix {
  padding: 16px 0;
}
mat-form-field.one-search-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
mat-form-field.one-search-field.one-search-field-squared .mat-form-field-flex {
  border-radius: 4px;
}
mat-form-field.one-search-field.one-search-field-fill .mat-form-field-underline {
  position: initial !important;
  bottom: 0;
}
mat-form-field.one-search-field.one-search-field-fill .mat-form-field-prefix {
  display: flex;
  flex-direction: row;
  align-items: center;
}
mat-form-field.one-search-field.one-search-field-fill .mat-form-field-flex .mat-form-field-infix {
  padding-left: 6px;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-underline {
  display: none;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-label-wrapper {
  top: auto;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-label {
  top: 0;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-flex {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-flex .mat-form-field-infix {
  margin-left: 6px;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-flex .mat-form-field-prefix {
  display: flex;
  flex-direction: row;
  align-items: center;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared) .mat-form-field-flex {
  border-radius: 24px;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared).one-search-field-fill .mat-form-field-underline {
  display: none;
}

.mat-slide-toggle.mat-disabled {
  opacity: 1 !important;
}
.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  box-shadow: none;
  height: 18px;
  width: 18px;
}

.one-form-field.mat-form-field-type-mat-input {
  min-width: 250px;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-prefix {
  margin-right: 6px;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-suffix {
  right: -12px;
  align-self: flex-start;
  margin-top: 5px;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-suffix [one-icon-button-flat] {
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-suffix [one-icon-button-flat] .mat-icon {
  display: inline-block;
}
.one-form-field.mat-form-field-type-mat-input .one-error-validation-icon {
  margin-right: 10px;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-label {
  top: 18px;
  font-size: 14px;
  line-height: 16px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 16px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-flex:has(textarea) {
  min-height: 52px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-flex:not(:has(textarea)) {
  height: 52px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-suffix {
  margin-top: 12px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 9px 0 13px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-input-element {
  line-height: 19px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-label {
  top: 25px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 5px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-label-wrapper {
  top: auto;
  bottom: 2px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-suffix .mat-icon.one-error-validation-icon {
  margin-top: 5px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  margin-top: 8px;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-flex {
  min-height: 48px;
  padding: 0 16px;
  transition: none;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline {
  display: none;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start {
  width: 10px !important;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-form-field-outline .mat-form-field-outline-start,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-form-field-outline .mat-form-field-outline-gap,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline:not(.mat-focused):not(.mat-form-field-invalid) .mat-form-field-outline .mat-form-field-outline-end {
  border-width: 1px;
}
.one-form-field.mat-form-field-type-mat-input textarea.mat-input-element {
  margin-bottom: 5px;
}

.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
  min-width: 96px;
  min-height: 36px;
}
.mat-raised-button[one-button-rounded],
.mat-flat-button[one-button-rounded],
.mat-stroked-button[one-button-rounded] {
  border-radius: 32px;
}
.mat-raised-button .mat-icon,
.mat-flat-button .mat-icon,
.mat-stroked-button .mat-icon {
  width: auto;
  height: auto;
}

.mat-raised-button + .mat-raised-button {
  margin-left: 24px;
}

.mat-raised-button:not([disabled]):hover, .mat-raised-button:not([disabled]).cdk-keyboard-focused {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

[one-icon-button].mat-icon-button {
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 0;
  padding: 0;
}
[one-icon-button].mat-icon-button .mat-button-wrapper {
  flex: 1;
}

[one-icon-button-raised].mat-raised-button,
[one-icon-button-flat].mat-flat-button {
  width: 36px;
  height: 36px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
[one-icon-button-raised].mat-raised-button .mat-button-wrapper,
[one-icon-button-flat].mat-flat-button .mat-button-wrapper {
  flex: 1;
}

[one-icon-button-flat].mat-flat-button:not([disabled]) {
  border-radius: 4px;
}

.mat-button-toggle-group.one-rounded-toggle-button {
  border-radius: 48px;
}
.mat-button-toggle-group.one-raised-toggle-button {
  border: none;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group.one-raised-toggle-button.one-raised-toggle-button-disabled {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group .mat-button-toggle:focus {
  outline: none;
}
.mat-button-toggle-group .mat-button-toggle .mat-button-toggle-button {
  min-height: 48px;
}
.mat-button-toggle-group .mat-button-toggle .mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: unset;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button .mat-icon {
  display: inline-flex;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button:not([disabled]) .mat-icon {
  display: inline-flex;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button[disabled][disabled] .mat-icon {
  display: inline-flex;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left-style: solid;
  border-left-width: 1px;
}

.mat-chip.mat-primary.one-chip-squared {
  border-radius: 4px;
}
.mat-chip.mat-primary.one-chip-outlined {
  border: 1px solid;
}
.mat-chip.mat-primary.mat-standard-chip[selected] mat-icon:not(.mat-chip-remove) {
  visibility: hidden;
}
.mat-chip.mat-primary.mat-standard-chip[selected] mat-icon:not(.mat-chip-remove):before {
  font-family: "One Icons";
  /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  content: "yes";
  visibility: visible;
  position: absolute;
  left: 10px;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled) {
  cursor: pointer;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled).cdk-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled).cdk-drag.cdk-drag-dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  z-index: 1;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: none;
}
.mat-chip.mat-primary.mat-standard-chip.one-chip-with-prefix-icon {
  padding: 7px 8px 7px 0;
}
.mat-chip.mat-primary.mat-standard-chip .mat-icon:not(.mat-chip-remove) {
  font-size: 24px;
  padding: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.mat-chip.mat-primary.mat-standard-chip .mat-icon.mat-chip-remove {
  margin-left: 11px;
}

.mat-progress-bar-fill:after {
  border-radius: 4px;
}

.mat-progress-bar {
  border-radius: 4px;
  height: 6px !important;
}

.mat-card.one-card {
  padding: 0;
  border-radius: 4px;
  z-index: 0;
}
.mat-card.one-card.one-card-flat, .mat-card.one-card[one-flat-card] {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.one-card.one-card-flat:not(:focus):not(.active):after, .mat-card.one-card[one-flat-card]:not(:focus):not(.active):after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
  z-index: -1;
  border: 1px solid;
}
.mat-card.one-card.one-card-flat.cdk-drag-dragging:after, .mat-card.one-card.one-card-flat.cdk-drag:hover:after, .mat-card.one-card[one-flat-card].cdk-drag-dragging:after, .mat-card.one-card[one-flat-card].cdk-drag:hover:after {
  border: none;
}
.mat-card.one-card.one-card-flat.active:after, .mat-card.one-card[one-flat-card].active:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
  z-index: -1;
  border: 1px solid;
}
.mat-card.one-card.one-card-clickable {
  cursor: pointer;
}
.mat-card.one-card.one-card-clickable:focus:not([disabled]) {
  border: none;
}
.mat-card.one-card.one-card-clickable:focus:not([disabled]), .mat-card.one-card.one-card-clickable:hover:not([disabled]):not(.cdk-drag-dragging) {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.one-card.one-card-clickable:focus:not([disabled]):after, .mat-card.one-card.one-card-clickable:hover:not([disabled]):not(.cdk-drag-dragging):after {
  border: none;
}
.mat-card.one-card:focus {
  outline: 0;
}
.mat-card.one-card:focus:not(.active):after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
}
.mat-card.one-card.cdk-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  z-index: 1;
}
.mat-card.one-card.cdk-drag.cdk-drag-dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-card.one-card.cdk-drag.cdk-drag-dragging:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
}
.mat-card.one-card .mat-card-actions {
  text-align: right;
  padding: 16px 0 0 0;
  margin: 0;
}
.mat-card.one-card > .mat-card-actions:last-child {
  margin-bottom: 0;
  padding-bottom: 16px;
}
.mat-card.one-card .mat-card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  min-height: 64px;
}
.mat-card.one-card .mat-card-image {
  width: 100%;
  margin: 0 0 16px 0;
}
.mat-card.one-card .mat-card-content {
  padding: 0 16px;
  margin: 0;
}
.mat-card.one-card > *:last-child {
  padding-bottom: 24px;
}
.mat-card.one-card[disabled][disabled] {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.one-card[disabled][disabled]:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
  border: 1px solid;
}
.mat-card.one-card.active.one-card-clickable:after {
  box-sizing: border-box;
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 4px;
}
.mat-card.one-card.active:before {
  font-family: "One Icons";
  /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  content: "yes";
  font-size: 18px;
  text-align: center;
  line-height: 20px;
  position: absolute;
  margin: 2px;
  width: 20px;
  height: 20px;
  top: 4px;
  right: 4px;
  z-index: 0;
  border-radius: 12px;
}

.mat-list-base mat-divider.mat-divider.mat-divider-horizontal {
  position: initial;
  width: calc(100% - 32px);
  margin-left: 16px;
}

.mat-list-base[onelist] .mat-list-item.mat-2-line,
.mat-list-base[onelist] .mat-list-option.mat-2-line {
  height: 64px;
}

.mat-list-base .mat-list-item.one-list-item-selected .mat-list-item-content:after {
  position: absolute;
  height: 100%;
  width: 4px;
  content: "";
  z-index: 51;
  top: 0;
  left: 0;
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  outline: none;
}

.mat-list-base > .mat-list-base .mat-list-option .mat-list-item-content {
  padding-left: 24px;
  box-sizing: border-box;
}

.mat-list-base[oneList] .mat-list-item .mat-list-item-content-reverse,
.mat-list-base[oneList] .mat-list-option .mat-list-item-content-reverse {
  flex-direction: row;
}
.mat-list-base[oneList] .mat-list-item .mat-list-item-content-reverse .mat-pseudo-checkbox,
.mat-list-base[oneList] .mat-list-option .mat-list-item-content-reverse .mat-pseudo-checkbox {
  margin-right: 16px;
}

.mat-list-base .mat-list-item[disabled] .mat-list-item-content,
.mat-list-item.mat-list-option[aria-disabled=true] .mat-list-item-content {
  cursor: default;
}

.mat-list-base.one-list-compact .mat-list-item,
.mat-list-base.one-list-compact .mat-list-option {
  height: 32px;
}
.mat-list-base.one-list-compact .mat-list-item .mat-list-item-content,
.mat-list-base.one-list-compact .mat-list-option .mat-list-item-content {
  font-size: 14px;
  line-height: 16px;
}

.mat-list-base.one-list-default .mat-list-item,
.mat-list-base.one-list-default .mat-list-option {
  height: 48px;
}
.mat-list-base.one-list-default .mat-list-item .mat-list-item-content,
.mat-list-base.one-list-default .mat-list-option .mat-list-item-content {
  font-size: 16px;
  line-height: 19px;
}

.mat-list-base.one-list-expanded .mat-list-item {
  height: 56px;
}
.mat-list-base.one-list-expanded .mat-list-item .mat-list-item-content {
  font-size: 16px;
  line-height: 19px;
}

.mat-list-base.one-list-big .mat-list-item {
  height: 64px;
}
.mat-list-base.one-list-big .mat-list-item .mat-line + .mat-line {
  line-height: 16px;
  font-size: 14px;
}

.mat-list-base[dense].one-list-dense .mat-list-item,
.mat-list-base[dense].one-list-dense .mat-list-option {
  height: 24px;
}
.mat-list-base[dense].one-list-dense .mat-list-item .mat-list-item-content,
.mat-list-base[dense].one-list-dense .mat-list-option .mat-list-item-content {
  font-size: 14px;
  line-height: 16px;
}

.mat-list-base.one-list-expanded .mat-list-item .mat-list-icon,
.mat-list-base.one-list-big .mat-list-item .mat-list-icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
  padding: 0;
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base.one-list-expanded .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base.one-list-big .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base.one-list-dense .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base.one-list-compact .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base.one-list-default .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
  padding-left: 12px;
}

mat-selection-list.mat-selection-list.mat-list-base mat-selection-list {
  padding-top: 0;
}

.mat-dialog-content[oneDialogContent] > .mat-list-base {
  outline: none;
  margin: 0 -16px;
}

.one-tab-group .mat-ink-bar,
[one-tab-group] .mat-ink-bar {
  border-radius: 4px 4px 0 0;
  z-index: 2;
}
.one-tab-group .mat-tab-header,
[one-tab-group] .mat-tab-header {
  border-bottom: none;
}
.one-tab-group .mat-tab-label .mat-tab-label-content,
.one-tab-group .mat-tab-link .mat-tab-label-content,
[one-tab-group] .mat-tab-label .mat-tab-label-content,
[one-tab-group] .mat-tab-link .mat-tab-label-content {
  flex-direction: column;
}
.one-tab-group.one-tab-icon-text .mat-tab-label, .one-tab-group[one-tab-icon-text] .mat-tab-label,
[one-tab-group].one-tab-icon-text .mat-tab-label,
[one-tab-group][one-tab-icon-text] .mat-tab-label {
  height: 64px;
}
.one-tab-group.one-tab-icon-text .mat-tab-label .mat-icon, .one-tab-group[one-tab-icon-text] .mat-tab-label .mat-icon,
[one-tab-group].one-tab-icon-text .mat-tab-label .mat-icon,
[one-tab-group][one-tab-icon-text] .mat-tab-label .mat-icon {
  margin-bottom: 3px;
}

.mat-dialog-container {
  margin: 0;
  padding: 0 !important;
  min-width: 80vw;
}
@media screen and (min-width: 500px) {
  .mat-dialog-container {
    min-width: 395px;
  }
}

one-table-header,
.mat-dialog-title[oneDialogTitle] {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  height: 61px;
  box-sizing: border-box;
  padding: 21px 16px;
  align-items: center;
}
one-table-header > .mat-icon,
.mat-dialog-title[oneDialogTitle] > .mat-icon {
  margin-right: 5px;
}

one-table-content,
.mat-dialog-content[oneDialogContent] {
  padding: 0;
  font-size: 16px;
  margin: 16px 16px 24px;
  overflow: unset;
}
@media screen and (min-width: 560px) {
  one-table-content,
.mat-dialog-content[oneDialogContent] {
    min-width: 395px;
  }
}
one-table-content[oneDialogContentList],
.mat-dialog-content[oneDialogContent][oneDialogContentList] {
  padding-top: 0;
  padding-bottom: 0;
}
one-table-content .mat-form-field,
.mat-dialog-content[oneDialogContent] .mat-form-field {
  width: 100%;
}

one-table-footer,
.mat-dialog-actions[oneDialogActions] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  padding-top: 24px;
  margin-bottom: 0;
  min-height: 0;
}
one-table-footer .mat-button-base + .mat-button-base,
.mat-dialog-actions[oneDialogActions] .mat-button-base + .mat-button-base {
  margin-left: 24px;
}

.mat-dialog-content[oneDialogContent] .one-dialog-subtitle + .mat-list-base {
  padding-top: 0;
}

.one-bottom-sheet-panel.mat-bottom-sheet-container {
  max-height: 50vh;
  padding: 8px 0;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list {
  padding: 0;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list .mat-list-item {
  height: 56px;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list .mat-list-item .mat-list-item-content {
  padding: 16px;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list .mat-list-item .mat-list-item-content .mat-list-text .mat-line {
  display: flex;
  align-items: flex-start;
  line-height: 21px;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list .mat-list-item .mat-list-item-content .mat-list-text .mat-line .list-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.one-tooltip.mat-tooltip {
  font-size: 12px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.one-menu {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.one-menu button.mat-menu-item {
  font-size: 14px;
}
.one-menu button.mat-menu-item.active:after, .one-menu button.mat-menu-item:active:after {
  position: absolute;
  height: 100%;
  width: 4px;
  content: "";
  z-index: 51;
  top: 0;
  left: 0;
}

.one-topbar.mat-toolbar {
  min-height: 48px;
}
.one-topbar.mat-toolbar[one-double-area-toolbar] {
  height: 96px;
}
.one-topbar.mat-toolbar [one-topbar-title] {
  font-size: 16px;
  line-height: 19px;
}
.one-topbar.mat-toolbar .mat-toolbar-row {
  padding: 0 24px;
  height: auto;
  min-height: 48px;
}
.one-topbar.mat-toolbar .mat-toolbar-row .mat-form-field-prefix .mat-icon {
  font-size: 24px;
}
.one-topbar.mat-toolbar .mat-toolbar-row .mat-chip-list-wrapper {
  margin: 0;
}
.one-topbar.mat-toolbar .mat-toolbar-row .mat-flat-button,
.one-topbar.mat-toolbar .mat-toolbar-row .mat-icon-button {
  margin-left: 7px;
}
.one-topbar.mat-toolbar .mat-toolbar-row .mat-flat-button.one-topbar-close-button,
.one-topbar.mat-toolbar .mat-toolbar-row .mat-icon-button.one-topbar-close-button {
  margin-left: -16px;
  margin-right: 4px;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] {
  font-weight: 400;
  font-size: 14px;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] a {
  font-weight: 400;
  text-decoration: none;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] a + a {
  margin-left: 24px;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] [one-topbar-title] {
  font-size: 14px;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] mat-divider.one-vertical-divider {
  height: 20px;
  border-right-width: 1px;
  margin-left: 8px;
  margin-right: 8px;
}
.one-topbar.mat-toolbar .mat-toolbar-row [one-topbar-profile-button] {
  padding: 0 8px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
}
.one-topbar.mat-toolbar .mat-toolbar-row [one-topbar-profile-button] .mat-button-wrapper .mat-icon {
  padding-right: 8px;
  padding-left: 8px;
}
.one-topbar.mat-toolbar .mat-toolbar-row [one-topbar-profile-button] .mat-button-wrapper .mat-icon:first-child {
  padding-left: 0;
}
.one-topbar.mat-toolbar .mat-toolbar-row [one-topbar-profile-button] .mat-button-wrapper .mat-icon:last-child {
  padding-right: 0;
}
.one-topbar.mat-toolbar .mat-toolbar-row[oneFilter] {
  padding: 0 16px 0 24px;
  border-top: 1px solid;
}
.one-topbar.mat-toolbar .mat-toolbar-row[oneFilter] [one-filter-clear-all-button] {
  margin-bottom: 6px;
}
.one-topbar.mat-toolbar .mat-toolbar-row[oneSingleFacets] {
  padding: 12px 16px 8px 24px;
}
.one-topbar.mat-toolbar .mat-toolbar-row one-banner-actions .mat-button-base + .mat-button-base {
  margin-left: 24px;
}
.one-topbar.mat-toolbar .mat-toolbar-row one-filtering-chip {
  font-size: 16px;
  line-height: normal;
  height: 36px;
  padding: 6px 0;
  margin-right: 12px;
}
.one-topbar.mat-tab-header,
.one-topbar .mat-tab-header {
  z-index: 2;
  border-top: 1px solid;
  padding: 0 24px;
}
.one-topbar.mat-tab-header .mat-tab-label,
.one-topbar.mat-tab-header .mat-tab-link,
.one-topbar .mat-tab-header .mat-tab-label,
.one-topbar .mat-tab-header .mat-tab-link {
  padding: 0 16px;
  min-width: auto;
  z-index: 2;
  opacity: 1;
}
.one-topbar.mat-tab-header .mat-tab-label .mat-tab-label-content,
.one-topbar.mat-tab-header .mat-tab-link .mat-tab-label-content,
.one-topbar .mat-tab-header .mat-tab-label .mat-tab-label-content,
.one-topbar .mat-tab-header .mat-tab-link .mat-tab-label-content {
  flex-direction: row;
}
.one-topbar.mat-tab-header .mat-tab-label one-badge,
.one-topbar.mat-tab-header .mat-tab-link one-badge,
.one-topbar .mat-tab-header .mat-tab-label one-badge,
.one-topbar .mat-tab-header .mat-tab-link one-badge {
  margin-left: 4px;
}

.one-about-pane.cdk-overlay-pane {
  max-height: 98vh;
  max-width: 75vw !important;
}
@media (max-width: 600px) {
  .one-about-pane.cdk-overlay-pane {
    max-height: 100vh;
    max-width: 100vw !important;
    height: 100vh;
    width: 100vw;
  }
}
.one-about-pane [one-window-about-container] {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.one-about-pane [one-window-about-container] [one-top-area] {
  height: 40px;
  width: 100%;
  margin-top: 16px;
}
.one-about-pane [one-window-about-container] [one-top-area] [one-close-button] {
  position: absolute;
  right: 16px;
}
.one-about-pane [one-window-about-container] [one-full-content-area] {
  margin: 24px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-title-area] {
  margin-bottom: 80px;
  position: relative;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-title-area] [one-title] {
  font-size: 38px;
  font-weight: 700;
  line-height: 51px;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-title-area] [one-subtitle] {
  font-size: 41px;
  font-style: italic;
  line-height: 54px;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-content-area] {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-bottom-area] {
  margin-top: 60px;
  position: relative;
  height: 40px;
  width: 100%;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-bottom-area] [one-close-button] {
  position: absolute;
  right: 0;
  height: 40px;
}
.one-about-pane [one-window-about-container] [one-about-content] {
  width: 100%;
}
.one-about-pane [one-window-about-container] [one-hide-on-mobile] {
  display: inherit;
}
.one-about-pane [one-window-about-container] [one-show-on-mobile] {
  display: none;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-top-area] {
  margin-top: 8px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-top-area] [one-close-button] {
  position: absolute;
  right: 8px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-full-content-area] {
  margin: 4px 24px 24px 24px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-full-content-area] [one-title-area] {
  margin-bottom: 48px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-full-content-area] [one-title-area] [one-title] {
  font-size: 20px;
  line-height: 27px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-full-content-area] [one-title-area] [one-subtitle] {
  font-size: 24px;
  line-height: 32px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-full-content-area] [one-bottom-area] {
  margin-top: 24px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-about-content] {
  width: 100%;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-hide-on-mobile] {
  display: none;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile [one-show-on-mobile] {
  display: inherit;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile .mat-toolbar .mat-toolbar-row {
  padding: 0;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile .mat-toolbar .mat-toolbar-row [one-about-mobile-topbar-title] {
  font-size: 16px;
}
.one-about-pane [one-window-about-container].one-about-pane-mobile .mat-toolbar .mat-toolbar-row [one-close-button] {
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 3px 8px 5px 8px;
  padding: 0;
}

@media screen and (max-width: 599px) {
  .cookie-acceptance-bar-panel {
    position: absolute !important;
    bottom: 0;
  }

  .cookie-acceptance-settings-panel {
    max-width: 90vw !important;
  }
}
one-cookie-acceptance-settings .mat-dialog-content[oneDialogContent] {
  margin: 0;
  padding: 16px 16px 24px;
}

.cookie-acceptance-bar-panel .mat-dialog-container {
  box-shadow: none;
  border-radius: 0;
}

.cookie-acceptance-bar-panel .mat-dialog-content[oneDialogContent] {
  font-size: 12px;
  line-height: 16px;
  padding: 0;
}
.cookie-acceptance-bar-panel .mat-dialog-actions[oneDialogActions] {
  padding: 0;
  flex-wrap: nowrap;
  margin-left: 17px;
  min-width: 275px;
}

.cookie-acceptance-settings-panel .mat-dialog-content[oneDialogContent],
.cookie-acceptance-bar-panel .mat-dialog-content[oneDialogContent] {
  min-width: auto;
}

.one-splash-screen {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one-splash-screen .mat-card.one-card {
  display: flex;
  flex-direction: column;
}
.one-splash-screen .mat-card.one-card.one-card-flat:after, .one-splash-screen .mat-card.one-card.one-card-flat:not(:focus):not(.active):after, .one-splash-screen .mat-card.one-card:after {
  border: none;
}
.one-splash-screen .mat-card.one-card .mat-card-header {
  display: flex;
  flex-direction: column;
  position: relative;
}
.one-splash-screen .mat-card.one-card .mat-card-header .mat-card-header-text {
  order: 2;
  margin: 24px 24px 0;
}
.one-splash-screen .mat-card.one-card .mat-card-header .mat-card-header-text .mat-card-title {
  font-weight: 700;
  min-height: inherit;
}
.one-splash-screen .mat-card.one-card .mat-card-header .mat-card-header-text .mat-card-subtitle {
  font-style: italic;
  margin: 0;
}
.one-splash-screen .mat-card.one-card .mat-card-header [one-splash-top] {
  order: 1;
  margin: 24px 24px 0;
  display: flex;
  flex-direction: row-reverse;
}
.one-splash-screen .mat-card.one-card .mat-card-content {
  flex-grow: 1;
  margin: 24px 24px 16px;
  padding: 0;
}
.one-splash-screen .mat-card.one-card [one-splash-bottom] {
  padding: 0;
}
.one-splash-screen .mat-card.one-card [one-splash-bottom] [one-splash-subarea] {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 24px 16px;
}

mat-vertical-stepper[oneVerticalStepper] .mat-stepper-vertical-line:before {
  border: none;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header {
  padding: 24px 16px;
  height: 16px;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-content {
  padding: 16px 16px 24px;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-icon {
  border: 1px solid;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true] {
  margin: 0 -16px;
  border-radius: 4px 4px 0 0;
  border-left: 8px solid;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true] .mat-step-icon {
  font-weight: 500;
  border: 2px solid;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-content[aria-expanded=true] {
  margin: 0 -16px;
  border-radius: 0 0 4px 4px;
  visibility: visible;
  border-left: 8px solid;
}
mat-vertical-stepper[oneVerticalStepper].mat-stepper-vertical {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-vertical-stepper[oneVerticalStepper].mat-stepper-vertical[appearance=non-numerical] .mat-step-icon {
  visibility: hidden;
  margin-left: -35px;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step {
  border-radius: 4px;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-icon.one-step-not-active {
  opacity: 87%;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-label,
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-label.mat-step-label-active {
  font-size: 16px;
  font-weight: 500;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header.one-step-edited .mat-step-label.mat-step-label-active:after {
  content: "— Edited";
  margin-left: 14px;
  font-size: 14px;
  font-weight: 400;
  opacity: 54%;
}
mat-vertical-stepper[oneVerticalStepper] .one-step-done-icon {
  display: none;
  position: absolute;
  top: 20px;
  margin-left: 28px;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true].mat-step-header .mat-step-header-ripple {
  border-bottom: 1px solid;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header.one-step-done .one-step-done-icon {
  display: inline;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-content-container {
  margin-left: auto;
}

.one-workflow-pane.cdk-overlay-pane {
  max-height: 100vh;
  max-width: 100vw !important;
  height: 100vh;
  width: 100vw;
}
.one-workflow-pane.cdk-overlay-pane .mat-dialog-container {
  position: fixed;
  bottom: 0;
  top: 100vh;
  width: 100%;
  overflow-y: hidden;
  -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  animation: slide-top 1s cubic-bezier(0.25, 0.1, 0.25, 1) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
}
.one-workflow-pane [one-window-workflow-container] {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.one-workflow-pane [one-window-workflow-container] [one-full-content-area] {
  margin: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.one-workflow-pane [one-window-workflow-container] [one-full-content-area] [one-content-area] {
  margin: 35px 160px 0 160px;
  flex-grow: 1;
}
.one-workflow-pane [one-window-workflow-container] [one-full-content-area] [one-bottom-area] {
  margin-top: 60px;
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.one-workflow-pane [one-window-workflow-container] [one-full-content-area] [one-bottom-area] button {
  margin: 0 12px;
}
.one-workflow-pane [one-window-workflow-container] [one-about-content] {
  width: 100%;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: #333333;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.05);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.05);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.05);
  color: #333333;
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0066cc;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0066cc;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cc0033;
}

.mat-optgroup-label {
  color: #737373;
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: #737373;
}
.mat-pseudo-checkbox::after {
  color: white;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0066cc;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0066cc;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #cc0033;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: white;
  color: #333333;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: #333333;
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #333333;
}

.mat-badge-content {
  color: white;
  background: #0066cc;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #0066cc;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #cc0033;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #8a8a8a;
  color: #8a8a8a;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #333333;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #0066cc;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #0066cc;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #cc0033;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #8a8a8a;
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0066cc;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0066cc;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #cc0033;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #000;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: lightgray;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #333333;
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #8a8a8a;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #0066cc;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #0066cc;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #cc0033;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: #efefef;
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: #333333;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px lightgray;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px lightgray;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px lightgray;
}

.mat-button-toggle-checked {
  background-color: lightgray;
  color: #737373;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #333333;
}

.mat-button-toggle-disabled {
  color: #8a8a8a;
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px lightgray;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: #333333;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: #737373;
}

.mat-checkbox-frame {
  border-color: #737373;
}

.mat-checkbox-checkmark {
  fill: white;
}

.mat-checkbox-checkmark-path {
  stroke: white !important;
}

.mat-checkbox-mixedmark {
  background-color: white;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0066cc;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0066cc;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #cc0033;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: #737373;
}

.mat-checkbox .mat-ripple-element {
  background-color: #000;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0066cc;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0066cc;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #cc0033;
}

.mat-chip.mat-standard-chip {
  background-color: #efefef;
  color: #333333;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #333333;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #000;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0066cc;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #cc0033;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0066cc;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: lightgray;
}

.mat-header-cell {
  color: #737373;
}

.mat-cell, .mat-footer-cell {
  color: #333333;
}

.mat-calendar-arrow {
  border-top-color: #737373;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #737373;
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: lightgray;
}

.mat-calendar-body-label {
  color: #737373;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #333333;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #8a8a8a;
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: #8a8a8a;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.05);
}

.mat-calendar-body-in-preview {
  color: lightgray;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 102, 204, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 102, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 102, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #0066cc;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 102, 204, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #333333;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 102, 204, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 102, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 102, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0066cc;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 102, 204, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(204, 0, 51, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(204, 0, 51, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(204, 0, 51, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #cc0033;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(204, 0, 51, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #0066cc;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #0066cc;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #cc0033;
}

.mat-date-range-input-inner[disabled] {
  color: #8a8a8a;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: #333333;
}

.mat-divider {
  border-top-color: lightgray;
}

.mat-divider-vertical {
  border-right-color: lightgray;
}

.mat-expansion-panel {
  background: white;
  color: #333333;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: lightgray;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.05);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: #333333;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #737373;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #8a8a8a;
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(115, 115, 115, 0.6);
}

.mat-hint {
  color: rgba(115, 115, 115, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #0066cc;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0066cc;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #cc0033;
}

.mat-focused .mat-form-field-required-marker {
  color: #0066cc;
}

.mat-form-field-ripple {
  background-color: rgba(211, 211, 211, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0066cc;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0066cc;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #cc0033;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0066cc;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0066cc;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #cc0033;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #cc0033;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #cc0033;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #cc0033;
}

.mat-error {
  color: #cc0033;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #737373;
}
.mat-form-field-appearance-legacy .mat-hint {
  color: #737373;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(211, 211, 211, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(211, 211, 211, 0.42) 0%, rgba(211, 211, 211, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(211, 211, 211, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(211, 211, 211, 0.42) 0%, rgba(211, 211, 211, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(211, 211, 211, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #8a8a8a;
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(211, 211, 211, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(211, 211, 211, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0066cc;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0066cc;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #cc0033;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #cc0033;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #8a8a8a;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(211, 211, 211, 0.06);
}

.mat-icon.mat-primary {
  color: #0066cc;
}
.mat-icon.mat-accent {
  color: #0066cc;
}
.mat-icon.mat-warn {
  color: #cc0033;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #737373;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #8a8a8a;
}

.mat-input-element {
  caret-color: #0066cc;
}
.mat-input-element::placeholder {
  color: rgba(115, 115, 115, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(115, 115, 115, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(115, 115, 115, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(115, 115, 115, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #0066cc;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #cc0033;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #cc0033;
}

.mat-list-base .mat-list-item {
  color: #333333;
}
.mat-list-base .mat-list-option {
  color: #333333;
}
.mat-list-base .mat-subheader {
  color: #737373;
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.05);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: #333333;
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: #737373;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.05);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #737373;
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid #737373;
  border-right: 2px solid #737373;
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid #737373;
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #b3d1f0;
}

.mat-progress-bar-buffer {
  background-color: #b3d1f0;
}

.mat-progress-bar-fill::after {
  background-color: #0066cc;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3d1f0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3d1f0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0066cc;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f0b3c2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f0b3c2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #cc0033;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #0066cc;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #0066cc;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #cc0033;
}

.mat-radio-outer-circle {
  border-color: #737373;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0066cc;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0066cc;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0066cc;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0066cc;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cc0033;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #cc0033;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: #000;
}

.mat-select-value {
  color: #333333;
}

.mat-select-placeholder {
  color: rgba(115, 115, 115, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: #8a8a8a;
}

.mat-select-arrow {
  color: #737373;
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0066cc;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0066cc;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #cc0033;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #cc0033;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #8a8a8a;
}

.mat-drawer-container {
  background-color: white;
  color: #333333;
}

.mat-drawer {
  background-color: white;
  color: #333333;
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px lightgray;
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px lightgray;
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px lightgray;
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px lightgray;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0066cc;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 102, 204, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0066cc;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0066cc;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 102, 204, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0066cc;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #cc0033;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(204, 0, 51, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #cc0033;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #0066cc;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 102, 204, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #0066cc;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 102, 204, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #cc0033;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(204, 0, 51, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: #737373;
}
.mat-step-header .mat-step-icon {
  background-color: #737373;
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #0066cc;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0066cc;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #cc0033;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #cc0033;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: #333333;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #cc0033;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: lightgray;
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: lightgray;
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #737373;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid lightgray;
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid lightgray;
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: #333333;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: #8a8a8a;
}

.mat-tab-header-pagination-chevron {
  border-color: #333333;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #8a8a8a;
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 209, 240, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0066cc;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 209, 240, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0066cc;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 179, 194, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #cc0033;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 209, 240, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #0066cc;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 209, 240, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #0066cc;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 179, 194, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #cc0033;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #efefef;
  color: #333333;
}
.mat-toolbar.mat-primary {
  background: #0066cc;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #0066cc;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #cc0033;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(77, 77, 77, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: #333333;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #0066cc;
}

.one-about-pane [one-window-about-container] [one-full-content-area] [one-title-area] [one-title] {
  color: #0066cc;
}
.one-about-pane [one-window-about-container] [one-full-content-area] [one-title-area] [one-subtitle] {
  color: #0066cc;
}

one-badge[appearance=warning] {
  background-color: #cc0033;
  color: #fff;
}
one-badge[appearance=issue] {
  background-color: #ffc414;
  color: #000;
}
one-badge[appearance=info] {
  background-color: #0066cc;
  color: white;
}

one-banner .one-banner-wrapper {
  background-color: white;
}
one-banner .one-banner-wrapper one-banner-content {
  color: #333333;
}

one-bottom-nav[appearance=primary] {
  background-color: #0066cc;
  color: white;
}
one-bottom-nav[appearance=primary] one-bottom-nav-item:not([active]) {
  color: #a5d2ff;
}
one-bottom-nav[appearance=primary] one-bottom-nav-item:not([active]) .mat-icon {
  color: #a5d2ff;
}
one-bottom-nav[appearance=primary] one-bottom-nav-item[active] {
  color: white;
}
one-bottom-nav[appearance=primary] one-bottom-nav-item[active] .mat-icon {
  color: white;
}
one-bottom-nav[appearance=secondary] {
  background-color: white;
  color: #0066cc;
}
one-bottom-nav[appearance=secondary] one-bottom-nav-item:not([active]) {
  color: #737373;
}
one-bottom-nav[appearance=secondary] one-bottom-nav-item:not([active]) .mat-icon {
  color: #737373;
}
one-bottom-nav[appearance=secondary] one-bottom-nav-item[active] {
  color: #0066cc;
}
one-bottom-nav[appearance=secondary] one-bottom-nav-item[active] .mat-icon {
  color: #0066cc;
}

one-breadcrumbs .breadcrumb div one-breadcrumb-element:not(:last-child) mat-icon {
  color: #737373;
}
one-breadcrumbs .breadcrumb div one-breadcrumb-element .mat-caption {
  color: #0066cc;
}
one-breadcrumbs .breadcrumb div one-breadcrumb-element .mat-small {
  color: #737373;
}

.mat-raised-button[disabled][disabled],
.mat-flat-button[disabled][disabled],
.mat-stroked-button[disabled][disabled] {
  color: #8a8a8a;
  background-color: #efefef;
}

.mat-raised-button.mat-primary .mat-ripple-element,
.mat-flat-button.mat-primary .mat-ripple-element {
  opacity: 0.35;
  background-color: #fff;
}
.mat-raised-button.mat-primary:not([disabled]):not(:active):hover .mat-button-focus-overlay,
.mat-flat-button.mat-primary:not([disabled]):not(:active):hover .mat-button-focus-overlay {
  opacity: 0.04;
}
.mat-raised-button.mat-primary:not([disabled]):not(:active):focus .mat-button-focus-overlay, .mat-raised-button.mat-primary:not([disabled]):not(:active).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-flat-button.mat-primary:not([disabled]):not(:active):focus .mat-button-focus-overlay,
.mat-flat-button.mat-primary:not([disabled]):not(:active).cdk-keyboard-focused .mat-button-focus-overlay {
  opacity: 0.12;
}

.mat-raised-button[one-secondary-button] .mat-ripple-element {
  opacity: 0.2;
  background-color: #000;
}
.mat-raised-button[one-secondary-button]:not([disabled]) {
  color: #0066cc;
  background-color: #fafafa;
}
.mat-raised-button[one-secondary-button]:not([disabled]):not(:active):hover .mat-button-focus-overlay {
  opacity: 0.04;
}
.mat-raised-button[one-secondary-button]:not([disabled]):not(:active).cdk-keyboard-focused .mat-button-focus-overlay {
  opacity: 0.12;
}

.mat-stroked-button {
  border-color: #bababa !important;
}
.mat-stroked-button[one-secondary-button] .mat-ripple-element {
  opacity: 0.2;
  background-color: #000;
}
.mat-stroked-button[one-secondary-button]:not([disabled]) {
  color: #0066cc;
  border-color: #bababa;
  background-color: #fafafa;
}
.mat-stroked-button[one-secondary-button]:not([disabled]):not(:active):hover .mat-button-focus-overlay {
  opacity: 0.04;
}
.mat-stroked-button[one-secondary-button]:not([disabled]):not(:active).cdk-keyboard-focused .mat-button-focus-overlay {
  opacity: 0.12;
}

[one-icon-button].mat-icon-button {
  color: #737373;
}
[one-icon-button].mat-icon-button[disabled][disabled] .mat-icon {
  color: #bababa;
}
[one-icon-button].mat-icon-button:not([disabled]):hover:not(:active) .mat-button-focus-overlay {
  opacity: 0.04;
}
[one-icon-button].mat-icon-button:not([disabled]).cdk-keyboard-focused:not(:active) .mat-button-focus-overlay {
  opacity: 0.12;
}

[one-icon-button-raised].mat-raised-button,
[one-icon-button-flat].mat-flat-button {
  color: #737373;
}
[one-icon-button-raised].mat-raised-button[disabled][disabled] .mat-icon,
[one-icon-button-flat].mat-flat-button[disabled][disabled] .mat-icon {
  color: #bababa;
}

[one-icon-button-raised].mat-raised-button .mat-ripple-element {
  opacity: 0.2;
  background-color: #000;
}
[one-icon-button-raised].mat-raised-button:not([disabled]) {
  background-color: #fafafa;
}
[one-icon-button-raised].mat-raised-button:not([disabled]):hover:not(:active) .mat-button-focus-overlay {
  opacity: 0.04;
}
[one-icon-button-raised].mat-raised-button:not([disabled]).cdk-keyboard-focused:not(:active) .mat-button-focus-overlay {
  opacity: 0.12;
}

[one-icon-button-flat].mat-flat-button {
  background-color: transparent;
}
[one-icon-button-flat].mat-flat-button .mat-ripple-element {
  opacity: 0.2;
  background-color: #000;
}
[one-icon-button-flat].mat-flat-button[disabled][disabled] {
  background-color: transparent;
}
[one-icon-button-flat].mat-flat-button:not([disabled]):hover:not(:active) .mat-button-focus-overlay {
  opacity: 0.04;
}
[one-icon-button-flat].mat-flat-button:not([disabled]).cdk-keyboard-focused:not(:active) .mat-button-focus-overlay {
  opacity: 0.12;
}

.mat-button-toggle-group.one-flat-toggle-button {
  border-color: #bababa;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-appearance-standard {
  background-color: #fafafa;
}
.mat-button-toggle-group .mat-button-toggle .mat-button-toggle-button .mat-button-toggle-label-content {
  color: #333;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button {
  background-color: #d3d3d3;
  color: #0066cc;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button .mat-button-toggle-label-content {
  color: #0066cc;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button .mat-icon {
  color: #0066cc;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button:not([disabled]) .mat-icon {
  color: #737373;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button[disabled][disabled] {
  background-color: #efefef;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button[disabled][disabled] .mat-button-toggle-label-content {
  color: #8a8a8a;
}
.mat-button-toggle-group .mat-button-toggle:not(.mat-button-toggle-checked) .mat-button-toggle-button[disabled][disabled] .mat-icon {
  color: #bababa;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left-color: #bababa;
}

.mat-card.one-card {
  color: #333;
}
.mat-card.one-card.one-card-flat:not(:focus):not(.active):after, .mat-card.one-card[one-flat-card]:not(:focus):not(.active):after {
  border-color: #d3d3d3;
}
.mat-card.one-card.one-card-flat.active:after, .mat-card.one-card[one-flat-card].active:after {
  border-color: #d3d3d3;
}
.mat-card.one-card.one-card-clickable .mat-ripple-element {
  background-color: #d2e9ff;
  opacity: 0.5;
}
.mat-card.one-card:focus:not(.active):after {
  background-color: #000;
  opacity: 0.12;
}
.mat-card.one-card.cdk-drag.cdk-drag-dragging:after {
  background-color: #000;
  opacity: 0.02;
}
.mat-card.one-card[disabled][disabled] {
  background-color: #fafafa;
}
.mat-card.one-card[disabled][disabled]:after {
  border-color: #d3d3d3;
}
.mat-card.one-card[disabled][disabled] .mat-button.mat-primary[disabled],
.mat-card.one-card[disabled][disabled] .mat-button.mat-accent[disabled],
.mat-card.one-card[disabled][disabled] .mat-button.mat-warn[disabled],
.mat-card.one-card[disabled][disabled] .mat-button[disabled][disabled],
.mat-card.one-card[disabled][disabled] .mat-icon-button.mat-primary[disabled],
.mat-card.one-card[disabled][disabled] .mat-icon-button.mat-accent[disabled],
.mat-card.one-card[disabled][disabled] .mat-icon-button.mat-warn[disabled],
.mat-card.one-card[disabled][disabled] .mat-icon-button[disabled][disabled],
.mat-card.one-card[disabled][disabled] .mat-stroked-button.mat-primary[disabled],
.mat-card.one-card[disabled][disabled] .mat-stroked-button.mat-accent[disabled],
.mat-card.one-card[disabled][disabled] .mat-stroked-button.mat-warn[disabled],
.mat-card.one-card[disabled][disabled] .mat-stroked-button[disabled][disabled] {
  color: inherit;
}
.mat-card.one-card.active {
  background-color: #d2e9ff;
}
.mat-card.one-card.active.one-card-clickable .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.25);
}
.mat-card.one-card.active:before {
  color: #d2e9ff;
  background-color: #0066cc;
}

.mat-checkbox {
  display: flex;
}

.mat-checkbox-frame {
  border-color: #737373;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #bababa;
  background-color: #efefef;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #bababa;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #8a8a8a;
}

.mat-chip.mat-primary {
  color: #333;
  background-color: #efefef;
}
.mat-chip.mat-primary.one-chip-outlined {
  border-color: #bababa;
}
.mat-chip.mat-primary.mat-standard-chip .mat-ripple-element:not(.cdk-drag-dragging) {
  background-color: #a5d2ff;
  opacity: 0.5;
}
.mat-chip.mat-primary.mat-standard-chip.mat-chip-selected {
  color: #333;
  background-color: #efefef;
}
.mat-chip.mat-primary.mat-standard-chip[selected] {
  border: none;
  color: #333;
  background-color: #d2e9ff;
}
.mat-chip.mat-primary.mat-standard-chip[selected] mat-icon:not(.mat-chip-remove):before {
  color: #0066cc;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled):focus:after, .mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled):hover:after {
  opacity: 0;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled):not(:active):hover:after {
  opacity: 0.04;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled):not(:active):focus:after {
  opacity: 0.12;
}
.mat-chip.mat-primary.mat-standard-chip:not(.mat-chip-disabled).cdk-drag.cdk-drag-dragging .mat-ripple-element {
  opacity: 0.02;
  background-color: black;
}
.mat-chip.mat-primary.mat-standard-chip .mat-icon:not(.mat-chip-remove) {
  background-color: #d3d3d3;
  color: #737373;
}
.mat-chip.mat-primary.mat-standard-chip .mat-icon.mat-chip-remove {
  color: #737373;
  opacity: 1;
}
.mat-chip.mat-primary.mat-standard-chip.mat-chip-disabled {
  opacity: 1;
  color: #8a8a8a;
  background-color: #efefef;
}
.mat-chip.mat-primary.mat-standard-chip.mat-chip-disabled .mat-icon:not(.mat-chip-remove) {
  opacity: 0.5;
}

.cookie-acceptance-bar-panel .mat-dialog-container {
  background-color: #333;
  color: #fff;
}
.cookie-acceptance-bar-panel .mat-dialog-container one-cookie-acceptance-bar a:not(.mat-list-item):not(.mat-tab-link):visited {
  color: #8a8a8a;
}
.cookie-acceptance-bar-panel .mat-dialog-container one-cookie-acceptance-bar a:not(.mat-list-item):not(.mat-tab-link):not(:visited) {
  color: #fff;
  text-decoration: underline;
}

one-cookie-acceptance-settings .mat-dialog-title {
  color: #333;
}
one-cookie-acceptance-settings .mat-dialog-content[oneDialogContent] {
  background-color: #fafafa;
}
one-cookie-acceptance-settings .mat-dialog-content[oneDialogContent] .one-acceptance-settings-cookies-wrapper .one-acceptance-settings-cookie .one-acceptance-settings-title {
  color: #333;
}

mat-calendar .mat-button .mat-button-wrapper {
  color: #737373;
}
mat-calendar .mat-calendar-next-button:after,
mat-calendar .mat-calendar-previous-button:after {
  color: #737373;
}
mat-calendar .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border: none;
  background-color: none;
}
mat-calendar .mat-calendar-table-header th {
  color: #737373;
}

/* stylelint-disable */
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 102, 204, 0.05);
}

/* stylelint-enable */
.mat-calendar-table-header-divider:after {
  background: #d3d3d3;
}

.mat-dialog-container {
  background-color: #fff;
}

one-table-header,
.mat-dialog-title[oneDialogTitle] {
  color: #333333;
}

one-dialog-subtitle {
  background-color: #efefef;
  color: #333333;
}

.mat-divider {
  border-color: #d3d3d3;
}

.one-editable-dropdown input::-ms-input-placeholder {
  color: #737373;
}
.one-editable-dropdown input:-ms-input-placeholder {
  color: #737373;
}
.one-editable-dropdown input::placeholder {
  color: #737373;
  opacity: 1;
}
.one-editable-dropdown.mat-form-field-disabled input::-ms-input-placeholder {
  color: #8a8a8a;
}
.one-editable-dropdown.mat-form-field-disabled input:-ms-input-placeholder {
  color: #8a8a8a;
}
.one-editable-dropdown.mat-form-field-disabled input::placeholder {
  color: #8a8a8a;
  opacity: 1;
}
.one-editable-dropdown.mat-form-field-disabled .mat-form-field-flex {
  cursor: default;
}
.one-editable-dropdown .mat-form-field-suffix [one-icon-button-flat] {
  background-color: transparent;
}
.one-editable-dropdown.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-label mat-label, .one-editable-dropdown.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-label mat-label {
  background: none;
}
.one-editable-dropdown.mat-form-field-appearance-outline .mat-form-field-outline:after {
  content: "";
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: inherit;
  pointer-events: none;
  background-color: #000;
}
.one-editable-dropdown.mat-form-field-appearance-outline:hover:not(.mat-form-field-disabled) .mat-form-field-outline:after {
  opacity: 0.04;
}
.one-editable-dropdown.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex:after {
  content: "";
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: inherit;
  pointer-events: none;
  background-color: #000;
}
.one-editable-dropdown.mat-form-field-appearance-fill:hover:not(.mat-form-field-disabled) .mat-form-field-wrapper .mat-form-field-flex:after {
  opacity: 0.04;
}

one-expandable .one-expandable-header [one-icon-button-flat] {
  border-color: #bababa;
  background-color: #fafafa;
}
one-expandable .one-expandable-header [one-icon-button-flat] .one-icons {
  color: #0066cc;
}

one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex {
  background-color: #efefef;
}
one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex:after {
  background-color: #000;
}
one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex:hover:after {
  opacity: 0.04;
}
one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex .mat-form-field-label {
  color: #333333;
}
one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex .mat-select-value {
  color: #333333;
}
one-filtering-chip .mat-form-field.one-filtering-chip .mat-form-field-flex .mat-select-arrow {
  color: #737373;
}
one-filtering-chip .mat-form-field.one-filtering-chip.mat-focused .mat-form-field-flex:after {
  opacity: 0.12;
}
one-filtering-chip .mat-form-field.one-filtering-chip.mat-focused .mat-form-field-flex .mat-form-field-label {
  color: #333;
}
one-filtering-chip .mat-form-field.one-filtering-chip.mat-focused .mat-form-field-flex .mat-select-arrow {
  color: #737373;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-selected .mat-form-field-flex {
  background-color: #e0ecf9;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-selected .mat-form-field-flex:after {
  background-color: #0066cc;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-selected .mat-form-field-flex .mat-select-value {
  color: #0066cc;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-selected .mat-form-field-flex .mat-select-arrow {
  color: #0066cc;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-labeled .mat-form-field-flex .mat-form-field-label {
  color: #737373;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-labeled .mat-form-field-flex .mat-select-value .mat-select-placeholder {
  color: #333333;
}
one-filtering-chip .mat-form-field.one-filtering-chip.one-filtering-chip-labeled.one-filtering-chip-selected-labeled .mat-form-field-flex .mat-form-field-label {
  color: #333;
}

.one-filtering-chip-panel.mat-select-panel .mat-checkbox {
  color: #333333;
}
.one-filtering-chip-panel.mat-select-panel .mat-option.mat-selected {
  background-color: #d2e9ff;
  color: #333333;
  text-decoration: none;
}
.one-filtering-chip-panel.mat-select-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-selected) {
  background-color: #f2f7fc;
}
.one-filtering-chip-panel.mat-select-panel .mat-option.mat-active:not(.mat-option-disabled) {
  background-color: #e0ecf9;
}

one-footer one-footer-header {
  background-color: white;
}
one-footer one-footer-content {
  background-color: #0066cc;
  color: white;
}

.one-form-field.mat-form-field-type-mat-input .mat-form-field-prefix {
  color: #737373;
}
.one-form-field.mat-form-field-type-mat-input .mat-form-field-suffix [one-icon-button-flat] {
  background-color: transparent;
}
.one-form-field.mat-form-field-type-mat-input .one-error-validation-icon {
  color: #c03;
}
.one-form-field.mat-form-field-type-mat-input .mat-hint,
.one-form-field.mat-form-field-type-mat-input .mat-form-field-label {
  color: #737373;
}
.one-form-field.mat-form-field-type-mat-input .mat-error {
  color: #c03;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #737373;
  background-color: #fff;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline mat-label {
  background-color: #fff;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.mat-focused .mat-form-field-label,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline, .one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.mat-focused .mat-form-field-label,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.mat-focused .mat-form-field-outline {
  color: #0066cc;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.mat-form-field-disabled .mat-icon, .one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.mat-form-field-disabled .mat-icon {
  color: #d3d3d3;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: #bababa;
  background-color: #fafafa;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-disabled .mat-hint {
  color: #8a8a8a;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-hint,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-form-field-label,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline.ng-invalid.ng-touched .mat-form-field-outline {
  color: #c03;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.ng-invalid.ng-touched .mat-hint,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.ng-invalid.ng-touched .mat-form-field-label,
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.ng-invalid.ng-touched .mat-form-field-outline {
  color: #c03;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-input-element:disabled {
  color: #8a8a8a;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #efefef;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: #737373;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill:not(.mat-focused) .mat-form-field-underline .mat-form-field-ripple {
  display: none;
}
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: #fafafa;
}

one-horizontal-stepper-container mat-horizontal-stepper {
  background-color: transparent;
}
one-horizontal-stepper-container mat-horizontal-stepper .mat-step-label-selected {
  color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper .mat-stepper-horizontal-line {
  border-top-color: #bababa;
}
one-horizontal-stepper-container mat-horizontal-stepper .mat-step-header.one-step-done + .mat-stepper-horizontal-line {
  border-top-color: #bababa;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-label.mat-step-label-active .mat-step-optional {
  color: #737373;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-label.mat-step-label-active {
  color: #737373;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-label.mat-step-label-active.mat-step-label-selected {
  color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-label.mat-step-label-active.mat-step-label-selected .mat-step-optional {
  color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper.one-horizontal-stepper-not-linear .mat-step-header .mat-step-icon.mat-step-icon-state-done {
  background-color: #00875a !important;
  color: #fff !important;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-label.mat-step-label-active {
  color: #333;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-icon {
  background-color: #bababa;
  color: #fff;
  border-color: #bababa;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-icon.mat-step-icon-selected {
  background-color: #0066cc;
  color: #fff;
  border-color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-icon.mat-step-icon-selected + .mat-step-label.mat-step-label-active {
  color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-end .mat-step-header .mat-step-icon.mat-step-icon-state-done {
  background-color: transparent;
  border-color: #00875a;
  color: #00875a;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon {
  background-color: transparent;
  color: #737373;
  border-color: #737373;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon.mat-step-icon-selected {
  background-color: #0066cc;
  color: #fff;
  border-color: #0066cc;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon.mat-step-icon-state-done {
  border-color: #00875a;
  color: #00875a;
}
one-horizontal-stepper-container mat-horizontal-stepper.mat-stepper-label-position-bottom .mat-step-header .mat-step-icon.mat-step-icon-state-done + .mat-step-label.mat-step-label-active {
  color: #333;
}

a:not(.mat-list-item):not(.mat-tab-link):not(.one-link-no-style):visited {
  color: #4b2f89;
}
a:not(.mat-list-item):not(.mat-tab-link):not(.one-link-no-style):not(:visited) {
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
}
a:not(.mat-list-item):not(.mat-tab-link):not(.one-link-no-style):not(:visited):hover, a:not(.mat-list-item):not(.mat-tab-link):not(.one-link-no-style):not(:visited):focus, a:not(.mat-list-item):not(.mat-tab-link):not(.one-link-no-style):not(:visited):active {
  text-decoration: underline;
}

.mat-icon {
  color: #737373;
}

.mat-list-item-content .mat-list-item-ripple.mat-ripple .mat-ripple-element {
  background-color: #a5d2ff;
  opacity: 0.5;
}

.mat-list-base[oneList],
.mat-list-base[oneList] *[mat-subheader] {
  background-color: white;
}

.mat-list-base .mat-list-item.one-list-item-selected .mat-list-item-content {
  color: #0066cc;
  background-color: #d2e9ff;
}
.mat-list-base .mat-list-item.one-list-item-selected .mat-list-item-content:after {
  background-color: #0066cc;
}

.mat-list-item.mat-list-option[aria-selected=true] .mat-list-item-content {
  background-color: #d2e9ff;
}

.mat-list-item.mat-list-option:focus .mat-list-item-content {
  background-color: #e0ecf9;
}

.mat-list-base .mat-list-item[disabled] .mat-list-item-content,
.mat-list-item.mat-list-option[aria-disabled=true] .mat-list-item-content {
  color: #8a8a8a;
  background-color: #fafafa;
}

.mat-list-base.one-list-big .mat-list-item .mat-line + .mat-line {
  color: #737373;
}

.mat-list-item:not([disabled]):hover {
  background-color: #f2f7fc;
}

.mat-list-item[type=button]:not([disabled]) .mat-list-item-content:hover,
.mat-list-item.mat-list-option:not([disabled]):not([aria-selected=true]) .mat-list-item-content:hover {
  background-color: #f2f7fc;
}

.one-menu button.mat-menu-item {
  color: #333333;
}
.one-menu button.mat-menu-item[disabled] {
  color: #8a8a8a;
  background-color: #fafafa;
}
.one-menu button.mat-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.one-menu button.mat-menu-item.active, .one-menu button.mat-menu-item:active {
  color: #0066cc;
  background-color: #d2e9ff;
}
.one-menu button.mat-menu-item.active mat-icon, .one-menu button.mat-menu-item:active mat-icon {
  color: #0066cc;
}
.one-menu button.mat-menu-item.active:after, .one-menu button.mat-menu-item:active:after {
  background-color: #0066cc;
}

one-mobile-header-stepper {
  background-color: #fff;
}

.mat-progress-bar-fill:after {
  background-color: #0066cc;
}

.mat-progress-bar-buffer {
  background-color: #d2e9ff;
}

.mat-radio-outer-circle {
  border-color: #737373;
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bababa;
  background-color: #efefef;
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #bababa;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #8a8a8a;
}

mat-form-field.one-search-field .mat-form-field-flex mat-icon.mat-icon {
  color: #737373;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-input-element {
  color: #333;
}
mat-form-field.one-search-field .mat-form-field-flex .mat-form-field-suffix [one-icon-button-flat] {
  background-color: transparent;
}
mat-form-field.one-search-field.mat-focused .mat-form-field-prefix mat-icon.mat-icon {
  color: #0066cc;
}
mat-form-field.one-search-field.one-search-field-squared.one-search-field-fill.mat-focused .mat-form-field-prefix mat-icon.mat-icon {
  color: #0066cc;
}
mat-form-field.one-search-field.one-search-field-fill .mat-form-field-underline:before {
  background-color: #737373;
}
mat-form-field.one-search-field.one-search-field-fill .mat-form-field-flex {
  color: #737373;
  background-color: #efefef;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-flex {
  background-color: #fff;
  border-color: #737373;
}
mat-form-field.one-search-field.one-search-field-outline .mat-form-field-flex .mat-form-field-label {
  color: #737373;
}
mat-form-field.one-search-field.one-search-field-outline.mat-focused .mat-form-field-flex {
  border-color: #0066cc;
  box-shadow: inset 0 0 0 1px #0066cc;
}
mat-form-field.one-search-field.one-search-field-outline.mat-focused .mat-form-field-label {
  color: inherit;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared) .mat-form-field-flex.mat-focused .mat-form-field-flex {
  border-color: #0066cc;
  box-shadow: 0 0 0 1px #0066cc;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared).one-search-field-fill.mat-focused .mat-form-field-flex {
  border-color: #0066cc;
  box-shadow: inset 0 0 0 2px #0066cc;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared).one-search-field-fill.mat-focused .mat-form-field-prefix mat-icon.mat-icon {
  color: #0066cc;
}
mat-form-field.one-search-field.one-search-field-rounded:not(.one-search-field-squared).one-search-field-fill.mat-focused .mat-form-field-label {
  color: inherit;
}

one-select .mat-form-field .mat-select-value {
  color: #333;
}
one-select .mat-form-field .mat-select-arrow {
  color: #737373;
}
one-select .mat-form-field [aria-activedescendant] .mat-select-arrow {
  color: #737373;
}
one-select .mat-form-field .mat-form-field-label {
  color: #333;
}
one-select .mat-form-field.one-select-filled .mat-form-field-flex {
  background-color: #efefef;
}
one-select .mat-form-field.one-select-outlined .mat-form-field-label-wrapper {
  border-right: 1px solid #d3d3d3;
}
one-select .mat-form-field.one-select-outlined .mat-form-field-flex {
  background-color: #fff;
  border: 1px solid #d3d3d3;
}
one-select .mat-form-field.one-select-disabled .mat-form-field-flex {
  background-color: #efefef;
}
one-select .mat-form-field.one-select-disabled .mat-select-value,
one-select .mat-form-field.one-select-disabled .mat-form-field-label {
  color: #8a8a8a !important;
}
one-select .mat-form-field.one-select-filled:not(.one-select-disabled) .mat-form-field-flex:after {
  background-color: #000;
}
one-select .mat-form-field.one-select-outlined:not(.one-select-disabled) .mat-form-field-flex:after {
  background-color: #000;
}
one-select .mat-form-field.one-select-filled:not(.one-select-disabled) .mat-form-field-flex:hover:after {
  opacity: 0.04;
}
one-select .mat-form-field.one-select-outlined:not(.one-select-disabled) .mat-form-field-flex:hover:after {
  opacity: 0.04;
}
one-select .mat-form-field.one-select-filled:not(.one-select-disabled) .mat-form-field-flex:focus-within:after {
  opacity: 0.12;
}
one-select .mat-form-field.one-select-outlined:not(.one-select-disabled) .mat-form-field-flex:focus-within:after {
  opacity: 0.12;
}
one-select .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #737373;
}
one-select .mat-form-field.mat-focused.mat-primary.one-select-filled:not(.one-select-disabled) .mat-form-field-flex:after {
  opacity: 0.12;
}
one-select .mat-form-field.mat-focused.mat-primary.one-select-outlined:not(.one-select-disabled) .mat-form-field-flex:after {
  opacity: 0.12;
}
one-select .mat-form-field.mat-focused.mat-primary .mat-form-field-label {
  color: #333;
}

.mat-select-panel.one-select-panel-small .mat-option .mat-option-ripple .mat-ripple-element {
  background-color: #a5d2ff;
}
.mat-select-panel.one-select-panel-small .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #d2e9ff;
}
.mat-select-panel.one-select-panel-small .mat-option.mat-active {
  background-color: #d2e9ff;
  color: #0066cc;
  text-decoration: none;
  border-left: 4px solid #0066cc;
}
.mat-select-panel.one-select-panel-small .mat-option.mat-option-disabled {
  color: #8a8a8a;
  background-color: #fafafa;
}
.mat-select-panel.one-select-panel-small .mat-option:hover:not(.mat-option-disabled), .mat-select-panel.one-select-panel-small .mat-option:focus:not(.mat-option-disabled) {
  background-color: #f2f7fc;
}
.mat-select-panel.one-select-panel-big .mat-option .mat-option-ripple .mat-ripple-element {
  background-color: #a5d2ff;
}
.mat-select-panel.one-select-panel-big .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #d2e9ff;
}
.mat-select-panel.one-select-panel-big .mat-option.mat-active {
  background-color: #d2e9ff;
  color: #0066cc;
  text-decoration: none;
  border-left: 4px solid #0066cc;
}
.mat-select-panel.one-select-panel-big .mat-option.mat-option-disabled {
  color: #8a8a8a;
  background-color: #fafafa;
}
.mat-select-panel.one-select-panel-big .mat-option:hover:not(.mat-option-disabled), .mat-select-panel.one-select-panel-big .mat-option:focus:not(.mat-option-disabled) {
  background-color: #f2f7fc;
}

.one-bottom-sheet-panel.mat-bottom-sheet-container {
  background-color: #fff;
}
.one-bottom-sheet-panel.mat-bottom-sheet-container .mat-list .mat-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mat-slide-toggle-bar {
  background-color: #737373;
}

.mat-slide-toggle.mat-disabled .mat-slide-toggle-content {
  color: #8a8a8a;
}
.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar {
  background-color: #bababa;
}
.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  background-color: #efefef;
  border: 1px solid #bababa;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #a5d2ff;
}

.one-slider.mat-slider {
  /*
   * If the slider value is 0, Angular Material has a different style.
   * We override the 0-value style according to the guidelines
   */
}
.one-slider.mat-slider:not(.mat-slider-disabled) .mat-slider-thumb {
  background: #0066cc;
  border: none;
}
.one-slider.mat-slider:not(.mat-slider-disabled):not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background: #0066cc;
  border: none;
}
.one-slider.mat-slider-min-value:not(.mat-slider-disabled).mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.one-slider.mat-slider-min-value:not(.mat-slider-disabled).mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background: #0066cc;
}
.one-slider.mat-slider:not(.mat-slider-disabled) .mat-slider-track-background {
  background: #bfd9f2;
}
.one-slider.mat-slider:not(.mat-slider-disabled).cdk-focused .mat-slider-focus-ring {
  transform: scale(1);
  opacity: 1;
  display: block;
  background: rgba(165, 210, 255, 0.8);
}

.slider-container .first-slider .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill {
  background: #bfd9f2;
}

.mat-snack-bar-container {
  background: none;
}

one-snack-bar .one-snack-bar-container {
  background-color: white;
}
one-snack-bar .one-snack-bar-container.success {
  border-color: #00875a;
  color: #333333;
}
one-snack-bar .one-snack-bar-container.success .one-snack-bar-icon-container {
  background-color: #00875a;
}
one-snack-bar .one-snack-bar-container.success .one-snack-bar-icon-container .mat-icon {
  color: white;
}
one-snack-bar .one-snack-bar-container.error {
  border-color: #cc0033;
  color: #333333;
}
one-snack-bar .one-snack-bar-container.error .one-snack-bar-icon-container {
  background-color: #cc0033;
}
one-snack-bar .one-snack-bar-container.error .one-snack-bar-icon-container .mat-icon {
  color: #fff;
}
one-snack-bar .one-snack-bar-container.warning {
  border-color: #ffc414;
  color: #333333;
}
one-snack-bar .one-snack-bar-container.warning .one-snack-bar-icon-container {
  background-color: #ffc414;
}
one-snack-bar .one-snack-bar-container.warning .one-snack-bar-icon-container .mat-icon {
  color: #000;
}
one-snack-bar .one-snack-bar-container.info {
  border-color: #bababa;
  color: #333333;
}
one-snack-bar .one-snack-bar-container.info .one-snack-bar-icon-container .mat-icon {
  color: #737373;
}

.one-splash-screen .mat-card.one-card .mat-card-header .mat-card-header-text .mat-card-title {
  color: #333;
}
.one-splash-screen .mat-card.one-card .mat-card-header .mat-card-header-text .mat-card-subtitle {
  color: #0066cc;
}

one-split-screen .one-split-screen-container .one-split-screen-grabber {
  color: #fff;
  background-color: #d3d3d3;
}
one-split-screen .one-split-screen-container .one-split-screen-grabber:after {
  background-color: #000;
}
one-split-screen .one-split-screen-container.one-split-screen-disabled .one-split-screen-grabber {
  color: #bababa;
  background-color: #fafafa;
}
one-split-screen .one-split-screen-container:not(.one-split-screen-disabled) .one-split-screen-grabber:active:after,
one-split-screen .one-split-screen-container:not(.one-split-screen-disabled) .one-split-screen-grabber:hover:after {
  opacity: 0.04;
}

one-table-spacing .mat-dialog-content[oneDialogContent] {
  background-color: #fafafa;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}
one-table-spacing .mat-dialog-content[oneDialogContent] .button-control-box .mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button {
  color: #0066cc;
}

one-editable input {
  border: 2px solid #0066cc;
  background-color: #fff;
  color: #333;
}

one-table-content.content-scrollable {
  box-shadow: inset 0 -4px 4px -2px rgba(0, 0, 0, 0.12);
}
one-table-content mat-table .mat-cell .mat-icon {
  color: #737373;
}
one-table-content table .mat-cell,
one-table-content table .mat-footer-cell,
one-table-content table .mat-header-cell,
one-table-content mat-table .mat-cell,
one-table-content mat-table .mat-footer-cell,
one-table-content mat-table .mat-header-cell {
  color: #333;
}
one-table-content table .mat-header-row,
one-table-content mat-table .mat-header-row {
  background-color: #efefef;
}
one-table-content table .mat-header-row .mat-header-cell,
one-table-content mat-table .mat-header-row .mat-header-cell {
  color: #333;
}
one-table-content table .mat-header-row .mat-header-cell .mat-checkbox-inner-container,
one-table-content mat-table .mat-header-row .mat-header-cell .mat-checkbox-inner-container {
  background-color: #fff;
}
one-table-content table .mat-row,
one-table-content table .mat-header-row,
one-table-content table .mat-footer-row,
one-table-content table th.mat-header-cell,
one-table-content table td.mat-cell,
one-table-content table td.mat-footer-cell,
one-table-content mat-table .mat-row,
one-table-content mat-table .mat-header-row,
one-table-content mat-table .mat-footer-row,
one-table-content mat-table th.mat-header-cell,
one-table-content mat-table td.mat-cell,
one-table-content mat-table td.mat-footer-cell {
  border-bottom-color: #d3d3d3;
}
one-table-content table .mat-row:hover:not(.expanded):not(.disabled),
one-table-content mat-table .mat-row:hover:not(.expanded):not(.disabled) {
  background-color: #f2f7fc;
}
one-table-content table .one-detail-row.expanded .mat-cell,
one-table-content mat-table .one-detail-row.expanded .mat-cell {
  border-left: 4px solid #0066cc;
}
one-table-content table .one-element-row.disabled,
one-table-content mat-table .one-element-row.disabled {
  background-color: #fafafa;
}
one-table-content table .one-element-row.disabled .mat-cell,
one-table-content mat-table .one-element-row.disabled .mat-cell {
  color: #8a8a8a;
}
one-table-content table .one-element-row.disabled .mat-cell .mat-icon,
one-table-content mat-table .one-element-row.disabled .mat-cell .mat-icon {
  color: #d3d3d3;
}
one-table-content table .one-element-row.expanded .mat-cell:first-child,
one-table-content mat-table .one-element-row.expanded .mat-cell:first-child {
  border-left: 4px solid #0066cc;
}
one-table-content table .one-element-detail .one-element-detail-content,
one-table-content mat-table .one-element-detail .one-element-detail-content {
  color: #333;
}
one-table-content table .one-detail-row.expanded .mat-cell:hover,
one-table-content mat-table .one-detail-row.expanded .mat-cell:hover {
  background-color: inherit;
}
one-table-content table .checked,
one-table-content mat-table .checked {
  background-color: #d2e9ff;
}
one-table-content[oneGroupedTable] table .one-element-row.isFather .mat-checkbox-layout,
one-table-content[oneGroupedTable] mat-table .one-element-row.isFather .mat-checkbox-layout {
  background-color: #fff;
}
one-table-content[oneGroupedTable] table .one-element-row.isFather:not(.isSecondLevel),
one-table-content[oneGroupedTable] mat-table .one-element-row.isFather:not(.isSecondLevel) {
  background-color: #efefef;
}
one-table-content[oneGroupedTable] table .one-element-row.isSecondLevel:not(.isThirdLevel),
one-table-content[oneGroupedTable] mat-table .one-element-row.isSecondLevel:not(.isThirdLevel) {
  background-color: #fff;
}

one-table-footer.content-scrollable:before {
  box-shadow: 0 -4px 4px -2px rgba(0, 0, 0, 0.12);
}

.one-tab-group .mat-tab-label,
.one-tab-group .mat-tab-link,
[one-tab-group] .mat-tab-label,
[one-tab-group] .mat-tab-link {
  opacity: 1;
  color: #737373;
}
.one-tab-group .mat-tab-label .mat-icon,
.one-tab-group .mat-tab-link .mat-icon,
[one-tab-group] .mat-tab-label .mat-icon,
[one-tab-group] .mat-tab-link .mat-icon {
  color: #737373;
}
.one-tab-group .mat-tab-label:focus, .one-tab-group .mat-tab-label.cdk-focused, .one-tab-group .mat-tab-label.cdk-keyboard-focused,
.one-tab-group .mat-tab-link:focus,
.one-tab-group .mat-tab-link.cdk-focused,
.one-tab-group .mat-tab-link.cdk-keyboard-focused,
[one-tab-group] .mat-tab-label:focus,
[one-tab-group] .mat-tab-label.cdk-focused,
[one-tab-group] .mat-tab-label.cdk-keyboard-focused,
[one-tab-group] .mat-tab-link:focus,
[one-tab-group] .mat-tab-link.cdk-focused,
[one-tab-group] .mat-tab-link.cdk-keyboard-focused {
  background-color: #e0ecf9 !important;
  color: #333;
}
.one-tab-group .mat-tab-label:hover,
.one-tab-group .mat-tab-link:hover,
[one-tab-group] .mat-tab-label:hover,
[one-tab-group] .mat-tab-link:hover {
  background-color: #f2f7fc;
}
.one-tab-group .mat-tab-label.mat-tab-label-active,
.one-tab-group .mat-tab-link.mat-tab-label-active,
[one-tab-group] .mat-tab-label.mat-tab-label-active,
[one-tab-group] .mat-tab-link.mat-tab-label-active {
  color: #0066cc;
}
.one-tab-group .mat-tab-label.mat-tab-label-active .mat-icon,
.one-tab-group .mat-tab-link.mat-tab-label-active .mat-icon,
[one-tab-group] .mat-tab-label.mat-tab-label-active .mat-icon,
[one-tab-group] .mat-tab-link.mat-tab-label-active .mat-icon {
  color: #0066cc;
}

one-timepicker .one-timepicker-form-field.one-form-field .mat-form-field-wrapper .mat-form-field-flex {
  background-color: #efefef;
}
one-timepicker .one-timepicker-form-field.one-form-field .mat-form-field-wrapper .mat-form-field-flex:after {
  background-color: #000;
}
one-timepicker .one-timepicker-form-field.one-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element:not(.one-timepicker-placeholder) {
  color: #333;
}
one-timepicker .one-timepicker-form-field.one-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element.one-timepicker-placeholder {
  color: #737373;
}
one-timepicker .one-timepicker-form-field.one-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element[disabled] {
  color: #8a8a8a !important;
}
one-timepicker .one-timepicker-form-field.one-form-field:not(.mat-form-field-disabled) .mat-form-field-wrapper .mat-form-field-flex:hover:after {
  opacity: 0.04;
}

.one-timepicker-options-panel.mat-autocomplete-panel .mat-option:hover {
  background-color: #f2f7fc;
}

.one-tooltip.mat-tooltip {
  background-color: #4d4d4d;
  color: white;
}

.one-topbar.mat-toolbar {
  background-color: white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  color: #333333;
}
.one-topbar.mat-toolbar .mat-toolbar-row {
  background-color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row > button {
  background-color: transparent;
}
[one-double-area-row] .one-topbar.mat-toolbar .mat-toolbar-row .mat-flat-button,
[one-double-area-row] .one-topbar.mat-toolbar .mat-toolbar-row .mat-icon-button {
  color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row .mat-flat-button:not(.mat-primary) .one-icons,
.one-topbar.mat-toolbar .mat-toolbar-row .mat-icon-button:not(.mat-primary) .one-icons {
  color: #737373;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] {
  background-color: #0066cc;
  color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] a {
  color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] a:active, .one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] a.active {
  color: #a5d2ff;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] .mat-flat-button,
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] .mat-icon-button {
  color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] .mat-flat-button .mat-icon,
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] .mat-icon-button .mat-icon {
  color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row[one-double-area-row] mat-divider.one-vertical-divider {
  color: white;
  border-color: white;
}
.one-topbar.mat-toolbar .mat-toolbar-row[oneFilter] {
  border-top-color: lightgray;
}
.one-topbar.mat-tab-header,
.one-topbar .mat-tab-header {
  background-color: white;
  border-top-color: lightgray;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-icon {
  background-color: transparent;
  color: #333;
  border-color: #333;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true] {
  background-color: #fff;
  border-left-color: #0066cc;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true] .mat-step-icon {
  color: #0066cc;
  border-color: #0066cc;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-content[aria-expanded=true] {
  background-color: #fff;
  border-left-color: #0066cc;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
}
mat-vertical-stepper[oneVerticalStepper].mat-stepper-vertical {
  background-color: #fafafa;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-icon.one-step-not-active {
  color: #8a8a8a;
  border-color: #8a8a8a;
}
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-label,
mat-vertical-stepper[oneVerticalStepper] .mat-step-header .mat-step-label.mat-step-label-active {
  color: #333;
}
mat-vertical-stepper[oneVerticalStepper] .one-step-done-icon {
  color: #00875a;
}
mat-vertical-stepper[oneVerticalStepper] .mat-vertical-stepper-header[aria-selected=true].mat-step-header .mat-step-header-ripple {
  border-bottom-color: #efefef;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f2f7fc;
}